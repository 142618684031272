<template>
  <section>
    <!-- :style="getStylediv" -->
    <div class="form-builder" ref="formBuilder">
      <div v-if="fieldsData && fieldsData.length" class="form-fields-holder">
        <!-- {{ form }} -->
        <!-- :style="getStyle(field)" -->
        <div
          class="form-group"
          v-for="(field, index) of fieldsData"
          :key="index"
        >
          <DateExecute
            :data="field"
            v-if="field.input_type === 'DATE' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></DateExecute>
          <DateTimeExecute
            :data="field"
            v-if="field.input_type === 'DATE_TIME' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></DateTimeExecute>
          <DateTimeRangeExecute
            :data="field"
            v-if="field.input_type === 'DATE_TIME_RANGE' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></DateTimeRangeExecute>
          <DateRangeExecute
            :data="field"
            v-if="field.input_type === 'DATE_RANGE' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></DateRangeExecute>

          <TimeExecute
            :data="field"
            v-if="field.input_type === 'TIME' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></TimeExecute>
          <TimeRangeExecute
            :data="field"
            v-if="field.input_type === 'TIME_RANGE' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></TimeRangeExecute>
          <FileExecuteView
            :data="field"
            v-if="field.input_type === 'FILE' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></FileExecuteView>

          <MultiLineTextExecute
            :data="field"
            v-if="
              (field.input_type === 'MULTI_LINE_TEXT' ||
              field.input_type === 'RICH_TEXT') && !checkhideFields(field)
            "
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></MultiLineTextExecute>
          <SingleLineTextExecute
            :data="field"
            v-if="
              (field.input_type === 'SINGLE_LINE_TEXT' ||
              field.input_type === 'EMAIL' ||
              field.input_type === 'PASSWORD' ||
              field.input_type === 'MASKED' ||
              field.input_type === 'HYPER_LINK') && !checkhideFields(field)
            "
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></SingleLineTextExecute>
          <MultiSelectExecute
            :data="field"
            v-if="field.input_type === 'MULTI_SELECT' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></MultiSelectExecute>
          <SelectExecute
            :data="field"
            v-if="field.input_type === 'SELECT' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></SelectExecute>
          <ListExecute
            :data="field"
            v-if="field.input_type === 'LIST' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></ListExecute>
          <YesOrNoExecute
            :data="field"
            v-if="field.input_type === 'YES_OR_NO' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></YesOrNoExecute>
          <NumberExecute
            :data="field"
            v-if="field.input_type === 'NUMBER' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></NumberExecute>
          <PhoneCountryCodeExecute
            :data="field"
            v-if="field.input_type === 'PHONE_COUNTRY_CODE' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></PhoneCountryCodeExecute>
          <CurrencyExecute
            :data="field"
            v-if="field.input_type === 'CURRENCY' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></CurrencyExecute>
          <HeadingExecute
            :data="field"
            v-if="field.input_type === 'HEADING' && !checkhideFields(field)"
            :form="form"
            :isFromDocument="isFromDocument"
          ></HeadingExecute>
          <CheckBoxExecute
            :data="field"
            v-if="field.input_type === 'CHECKBOX' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :singleCompanyTemplateCheckBoxArray="
              singleCompanyTemplateCheckBoxArray
            "
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></CheckBoxExecute>
          <CheckBoxGroupExecute
            :data="field"
            v-if="field.inputType === 'CHECKBOX_GROUP' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></CheckBoxGroupExecute>
          <RadioGroupExecute
            :data="field"
            v-if="field.inputType === 'RADIO_BUTTON_GROUP' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></RadioGroupExecute>
          <RadioExecute
            :data="field"
            v-if="field.input_type === 'RADIO' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></RadioExecute>
          <ESignatureExecute
            :data="field"
            v-if="field.input_type === 'SIGNATURE' && !checkhideFields(field)"
            :form="form"
            :isFromDocument="isFromDocument"
          ></ESignatureExecute>

          <GlobalVariableExecute
            :data="field"
            v-if="field.input_type === 'GLOBAL_VARIABLE' && !checkhideFields(field)"
            :form="form"
            :fieldsData="fieldsData"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
          ></GlobalVariableExecute>
          <EntityVariableExecute
            :data="field"
            v-if="field.input_type === 'ENTITY_VARIABLE' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :fieldsData="fieldsData"
            :isFromDocument="isFromDocument"
            @entityDataUpdated="setDataToEntityVariables"
            @clearEntityFields="unsetEntityVariables"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></EntityVariableExecute>

          <EntityExecute
            :data="field"
            v-if="field.input_type === 'ENTITY' && !checkhideFields(field)"
            :form="form"
            :hasLabel="hasLabel"
            :fieldsData="fieldsData"
            :childRelationshipsData="childRelationshipsData"
            @entityDataUpdated="setDataToEntityVariables"
            @clearEntityFields="unsetEntityVariables"
            :checkIsDisabled="checkIsDisabled(field)"
            @applyFormRules="rulesEventEmitter"
          ></EntityExecute>

          <ParagraphExecute
            :data="field"
            v-if="field.input_type === 'PARAGRAPH'"
            :form="form"
            :isFromDocument="isFromDocument"
          ></ParagraphExecute>
          <SingleLineContentExecute
            :data="field"
            v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
            :form="form"
            :isFromDocument="isFromDocument"
          ></SingleLineContentExecute>
          <AuthorizedSignatureExecute
            :data="field"
            v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
            :form="form"
            :isFromDocument="isFromDocument"
          ></AuthorizedSignatureExecute>
          <ImageExecute
            :data="field"
            v-if="field.input_type === 'IMAGE' || field.input_type === 'GIF'"
            :form="form"
            :isFromDocument="isFromDocument"
          ></ImageExecute>
          <VideoExecute
            :data="field"
            v-if="field.input_type === 'VIDEO'"
            :form="form"
            :isFromDocument="isFromDocument"
          ></VideoExecute>
          <FormulaExecute
            :data="field"
            v-if="field.input_type === 'FORMULA' && !checkhideFields(field)"
            :form="form"
            :isFromDocument="isFromDocument"
            :profilePage="false"
            :fromRepeatable="false"
            :hasLabel="hasLabel"
          ></FormulaExecute>
          <ActionButtonExecute
            :data="field"
            v-if="field.input_type === 'ACTION_BUTTON' && !checkhideFields(field)"
            :form="form"
            :fieldsData="fieldsData"
            :isFromDocument="isFromDocument"
          ></ActionButtonExecute>
          <HtmlExecute
            :data="field"
            v-if="field.input_type === 'HTML_CONTENT'"
            :form="form"
            :isFromDocument="isFromDocument"
          ></HtmlExecute>
          <HorizontalLineExecute
            :data="field"
            v-if="field.input_type === 'HORIZONTAL_LINE'"
            :form="form"
            :isFromDocument="isFromDocument"
          >
          </HorizontalLineExecute>

          <DIVExecute
            :data="field"
            v-if="field.input_type === 'DIVISION'"
            :form="form"
            :isFromDocument="isFromDocument"
          >
          </DIVExecute>
          <AggregateFunctionExecute
            :data="field"
            v-if="field.input_type === 'AGGREGATE_FUNCTION'"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
          ></AggregateFunctionExecute>
          <AutoIncrementExecute
            :data="field"
            v-if="field.input_type === 'AUTO_INCREMENT_NUMBER'"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
            :entityDataId="getEntityDataId"
            :entityId="getEntityId"
          ></AutoIncrementExecute>
          <QuestionExecute
            :data="field"
            v-if="field.input_type === 'QUESTION'"
            :form="form"
          ></QuestionExecute>
          <WeekDaysExecute
            :data="field"
            v-if="field.input_type == 'WEEKDAYS'"
            :form="form"
            :hasLabel="hasLabel"
          >
          </WeekDaysExecute>
          <StarRatingExecute
            :data="field"
            v-if="field.input_type === 'STAR_RATING'"
            :form="form"
            :hasLabel="hasLabel"
            :isFromDocument="isFromDocument"
          ></StarRatingExecute>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
var fieldsData = require("../templates/fields.json");

export default {
  mixins: [
    TemplateBuilderHelper,
  ],
  name: "Entity-PrimaryDataCollectionForm",
  components: {
    DateExecute: () =>
      import("../templates/formComponentsExecute/DateExecute.vue"),
    DateTimeExecute: () =>
      import("../templates/formComponentsExecute/DateTimeExecute.vue"),
    DateTimeRangeExecute: () =>
      import("../templates/formComponentsExecute/DateTimeRangeExecute.vue"),
    DateRangeExecute: () =>
      import("../templates/formComponentsExecute/DateRangeExecute.vue"),
    TimeExecute: () =>
      import("../templates/formComponentsExecute/TimeExecute.vue"),
    TimeRangeExecute: () =>
      import("../templates/formComponentsExecute/TimeRangeExecute.vue"),
    FileExecuteView: () =>
      import("../templates/formComponentsExecute/FileExecuteView.vue"),
    MultiLineTextExecute: () =>
      import("../templates/formComponentsExecute/MultiLineTextExecute.vue"),
    SingleLineTextExecute: () =>
      import("../templates/formComponentsExecute/SingleLineTextExecute.vue"),
    MultiSelectExecute: () =>
      import("../templates/formComponentsExecute/MultiSelectExecute.vue"),
    NumberExecute: () =>
      import("../templates/formComponentsExecute/NumberExecute.vue"),
    PhoneCountryCodeExecute: () =>
      import("../templates/formComponentsExecute/PhoneCountryCodeExecute.vue"),
    SelectExecute: () =>
      import("../templates/formComponentsExecute/SelectExecute.vue"),
    YesOrNoExecute: () =>
      import("../templates/formComponentsExecute/YesOrNoExecute.vue"),
      ListExecute: ()=>
        import("../templates/formComponentsExecute/ListExecute.vue"),
    HeadingExecute: () =>
      import("../templates/formComponentsExecute/HeadingExecute.vue"),
    CheckBoxExecute: () =>
      import("../templates/formComponentsExecute/CheckBoxExecute.vue"),
    CheckBoxGroupExecute: () =>
      import("../templates/formComponentsExecute/CheckBoxGroupExecute"),
    RadioGroupExecute: () =>
      import("../templates/formComponentsExecute/RadioButtonGroupExecute"),
    RadioExecute: () =>
      import("../templates/formComponentsExecute/RadioExecute.vue"),
    ESignatureExecute: () =>
      import("../templates/formComponentsExecute/ESignatureExecute.vue"),
    ParagraphExecute: () =>
      import("../templates/formComponentsExecute/ParagraphExecute.vue"),
    GlobalVariableExecute: () =>
      import("../templates/formComponentsExecute/GlobalVariableExecute.vue"),
    EntityVariableExecute: () =>
      import("../templates/formComponentsExecute/EntityVariableExecute.vue"),
    SingleLineContentExecute: () =>
      import("../templates/formComponentsExecute/SingleLineContentExecute.vue"),
    AuthorizedSignatureExecute: () =>
      import(
        "../templates/formComponentsExecute/AuthorizedSignatureExecute.vue"
      ),
    ImageExecute: () =>
      import("../templates/formComponentsExecute/ImageExecute.vue"),

    EntityExecute: () =>
      import("../templates/formComponentsExecute/EntityExecute"),
    FormulaExecute: () =>
      import("../templates/formComponentsExecute/FormulaExecute.vue"),
    ActionButtonExecute: () =>
      import("../templates/formComponentsExecute/ActionButtonExecute.vue"),
    HtmlExecute: () =>
      import("../templates/formComponentsExecute/HtmlExecute.vue"),
    AggregateFunctionExecute: () =>
      import("../templates/formComponentsExecute/AggregateFunctionExecute.vue"),
    VideoExecute: () =>
      import("../templates/formComponentsExecute/VideoExecute.vue"),
    HorizontalLineExecute: () =>
      import("../templates/formComponentsExecute/HorizontalLineExecute.vue"),
    DIVExecute: () =>
      import("../templates/formComponentsExecute/DIVExecute.vue"),
    CurrencyExecute: () =>
      import("../templates/formComponentsExecute/CurrencyExecute.vue"),
    AutoIncrementExecute: () =>
      import("../templates/formComponentsExecute/AutoIncrementExecute.vue"),
    QuestionExecute: () =>
      import("../templates/formComponentsExecute/QuestionExecute"),
    WeekDaysExecute: () =>
      import("../templates/formComponentsExecute/WeekDaysExecute.vue"),
    StarRatingExecute: () =>
      import("../templates/formComponentsExecute/StarRatingExecute.vue"),
  },
  data() {
    return {
      fieldsData: [],
      currentFormBuilderWidth: null,
      storedFormBuilderWidth: null,
      isFlow: false,
      formHeight: 600,
      loading: false,
      fieldActions: [],
      templateStyles: {},
      hasLabel: true,
      singleCompanyTemplateCheckBoxArray: [],
      disabledFields: [],
      hideFields: [],
    };
  },
  props: [
    "fieldsListData",
    "form",
    "isFromDocument",
    "relationshipDetails",
    "childRelationshipsData",
    "entityDataId",
    "parentyEntityId",
    "templateRules"
  ],
  computed: {
    textFieldPreviewClass() {
      return (inputType) => {
        const styles = ["common-field-preview"];
        if (
          inputType === "SINGLE_LINE_TEXT" ||
          inputType === "PASSWORD" ||
          inputType === "EMAIL" ||
          inputType === "MASKED" ||
          inputType === "HYPER_LINK"
        ) {
          styles.push("single-line-text-field-preview");
        } else if (inputType === "MULTI_LINE_TEXT") {
          styles.push("multi-line-text-field-preview");
        } else if (
          inputType === "NUMBER" ||
          inputType == "PHONE" ||
          inputType == "FAX"
        ) {
          styles.push("number-field-preview");
        } else if (inputType === "DATE") {
          styles.push("date-field-preview");
        }

        return styles;
      };
    },

    getStylediv() {
      return `height:${this.formHeight}px;` + this.getElementStyle;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.templateStyles) {
        let type = this.templateStyles.border_type
          ? this.templateStyles.border_type
          : "solid";
        let size = this.templateStyles.border_size
          ? this.templateStyles.border_size + "px"
          : "0px";
        let color = this.templateStyles.border_color
          ? this.templateStyles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.templateStyles.background
          ? `background: ${this.templateStyles.background}`
          : "";
      }
      return borderStyle;
    },
    fieldActionsObject() {
      const obj = {};
      this.fieldActions.forEach((action) => {
        obj[action.inputType] = action;
      });

      return obj;
    },
    getEntityDataId() {
      return this.entityDataId || this.$route.query.dataId;
    },
    getEntityId() {
      return this.parentyEntityId || this.$route.params.entity_id;
    },
  },

  async mounted() {
    await this.getAllFieldList();
    this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
    this.setTemplateElements(this.fieldsListData);
  },

  methods: {
    checkIsDisabled(field) {
      return this.disabledFields.find((e) => e == field.key) ? true : false;
    },
    checkhideFields(field) {
      return this.hideFields.find((e) => e == field.key) ? true : false;
    },
    getDefaultSizeRangesByFieldType(inputType) {
      if (
        inputType === "SINGLE_LINE_TEXT" ||
        inputType === "PASSWORD" ||
        inputType === "EMAIL" ||
        inputType === "MASKED" ||
        inputType === "HYPER_LINK"
      ) {
        inputType = "SINGLE_LINE_TEXT";
      } else if (inputType === "MULTI_LINE_TEXT" || inputType === "RICH_TEXT") {
        inputType = "MULTI_LINE_TEXT";
      } else if (
        inputType === "NUMBER" ||
        inputType === "PHONE" ||
        inputType === "FAX"
      ) {
        inputType = "NUMBER";
      } else if (
        inputType === "DATE" ||
        inputType === "TIME" ||
        inputType === "DATE_TIME" ||
        inputType === "DATE_TIME_RANGE" ||
        inputType === "DATE_RANGE"
      ) {
        inputType = "DATE";
      }
      let data = this.fieldActionsObject[inputType];
      if (data?.min_height && data?.max_height) {
        return {
          min_height: data.min_height,
          min_width: "100%",
          max_height: data.max_height,
          max_width: "100%",
        };
      }
    },
    getStyle(item) {
      let style = `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;`;
      return style;
    },

    calculateRelativeLeft(x) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
        );
      }
      return x;
    },

    calculateRelativeTop(y) {

      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
        );
      }
      return y;
    },
    getHandleByType(type) {
      let defaultHandles = ["tl", "tm", "tr", "mr", "br", "bm", "bl", "ml"];
      switch (type) {
        case "SELECT":
          return ["mr", "ml"];
        case "date":
          return ["mr", "ml"];
        case "CHECKBOX":
          return defaultHandles;
        case "RADIO":
          return defaultHandles;
        case "MULTI_LINE_TEXT":
          return ["mr", "ml"];
        default:
          return defaultHandles;
      }
    },
    calculateRelativeWidth(width) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
        );
      }
      return width;
    },

    setSizeAndPositionForElements(elements) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
          ...this.getDefaultSizeRangesByFieldType(field.input_type),
        };
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);

        let width = this.calculateRelativeWidth(field.width);
        let height = this.calculateRelativeWidth(field.height);

        if (width > field.max_width) {
          field.width = field.max_width;
        } else if (width < field.min_width) {
          field.width = field.min_width;
        } else {
          field.width = width;
        }
        if (height > field.max_height) {
          field.height = field.max_height;
        } else if (height < field.min_height) {
          field.height = field.min_height;
        } else {
          field.height = height;
        }
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        if (this.$route.params.entity_id || this.parentyEntityId) {
          field.parent_entityId =
            this.parentyEntityId || this.$route.params.entity_id;
          field.parent_entityDataId =
            this.entityDataId || this.$route.query.dataId;
        }
        if (field.input_type === "DATA_TABLE") {
          field.data_table_columns = (field.data_table_columns || []).map(
            (e) => {
              e.parent_entityId =
                this.parentyEntityId || this.$route.params.entity_id;
              e.parent_entityDataId =
                this.entityDataId || this.$route.query.dataId;
              return e;
            }
          );
        }
        return field;
      });
    },
    async setTemplateElements(templateData) {
      let defaultSection = templateData;
      defaultSection = defaultSection.sort((a, b) => a.order - b.order);
      this.fieldsData = this.setSizeAndPositionForElements(defaultSection);
      defaultSection = defaultSection.sort((a, b) => {
        return a.y - b.y;
      });
      if (
        templateData &&
        templateData.sections &&
        templateData.sections[0] &&
        templateData.sections[0].checkBoxGroup
      ) {
        this.singleCompanyTemplateCheckBoxArray =
          templateData.sections[0].checkBoxGroup;
      }
      let lastElement = defaultSection[this.fieldsData.length - 1];
      this.formHeight = lastElement.y + lastElement.height + 100;
      if(this.templateRules){
        this.rulesEventEmitter();
      }
    },
    rulesEventEmitter() {
      if (this.templateRules) {
        this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
      }
    },
    async getAllFieldList() {
      try {
        if (fieldsData) {
          this.fieldActions = [
            ...fieldsData.allFormFields,
            ...fieldsData.allContentFields,
            ...fieldsData.allAdvancedFields,
          ];
        }
      } catch (error) {
        console.log("getAllFieldList",error);
      }
    },

    async setDataToEntityVariables(data, parent, label, changed) {
      let entityId = data && data._id ? data._id : null;
      if (label) {
        this.$set(this.form, parent.key + "/name", label);
      }
      this.fieldsData.forEach((field) => {
        if (
          field.relationship_key == parent.key &&
          (field.field_assignable == "read_only" ||
            !this.form[field.key] ||
            changed)
        ) {
          if (field.global_variable_entity_field.includes("~")|| field.variable_action === 'RESOURCE_AVERAGE') {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (parent?.allow_multiple) {
              if (data.length) {
                let dataValues = [],
                  result = 0,
                  min;
                (data || []).forEach((e) => {
                  if (e?.entityData?.[templateId]?.[key]) {
                    if (field.inputType == "DATA_TABLE") {
                      if (e.entityData[templateId][key].length) {
                        e?.entityData[templateId][key].forEach((dt) => {
                          dataValues.push({
                            ...dt,
                            ...{ parentDataId: e._id, parentDataName: e.parentDataName, },
                          });
                        });
                      }
                    } else if (this.isNumber(e.entityData[templateId][key])) {
                      let v = e.entityData[templateId][key];
                      if (
                        field.variable_action == "SUM" ||
                        field.variable_action == "AVERAGE"
                      ) {
                        result = result + parseInt(v);
                      } else if (
                        field.variable_action == "HIGHEST" &&
                        v > result
                      ) {
                        result = parseInt(v);
                      } else if (field.variable_action == "LOWEST") {
                        if (v < min || min == undefined) {
                          min = parseInt(v);
                        }
                      }
                    }
                  }
                });
                if (field.inputType == "DATA_TABLE") {
                  this.$set(this.form, field.key, dataValues);
                } else {
                  if (field.variable_action == "AVERAGE") {
                    let avg = result / data.length;
                    this.$set(this.form, field.key, avg);
                  } else if (field.variable_action == "LOWEST") {
                    this.$set(this.form, field.key, min);
                  } else {
                    this.$set(this.form, field.key, result);
                  }
                }
              }
            } else {
              if (
                data &&
                data.entityData &&
                data.entityData[templateId] &&
                data.entityData[templateId][key]
              ) {
                value = data.entityData[templateId][key];

                if (field.inputType == "ENTITY") {
                  valueName = data.entityData[templateId][key + "/name"];
                  this.$set(this.form, field.key + "/default", [value]);
                }
              }

              if (this.form[field.key]) {
                this.form[field.key] = value;
                if (valueName) {
                  this.form[field.key + "/name"] = valueName;
                }
              } else {
                this.$set(this.form, field.key, value);
                if (valueName) {
                  this.$set(this.form, field.key + "/name", valueName);
                }
              }
            }
          }
        }
      });
      if (!this.form.parent_data) {
        this.form.parent_data = {};
      }
      if (entityId) {
        this.form.parent_data[entityId] = data;
      } else {
        this.form.parent_data = {};
      }
    },
    isNumber(value) {
      return typeof value === "number" || !isNaN(parseFloat(value));
    },
    async unsetEntityVariables(parent) {
      (this.getFieldsData || []).forEach((field) => {
        if (field.relationship_key == parent.key) {
          this.$set(this.form, field.key, "");
        }
      });
      // if (!this.form.parent_data) {
      //   this.form.parent_data = {};
      // }

      // if (entityId) {
      //   this.form.parent_data[entityId] = [];
      // }
    },
  },

  watch: {
    // "$route.params.workflow_data_id"(id) {
    //   this.workflowDataId = id;
    // },
    form: async function () {
      // watch it
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-btn {
  border-radius: 2px !important;
  padding: 0.45em 0.65em;
  border: 1px solid #285ed3;
  background: #285ed3;
  color: #ffffff;
  &:hover {
    color: #285ed3 !important;
    background: transparent;
    border: 1px solid #285ed3;
  }
}
.edit-btn {
  border-radius: 2px;
  padding: 0.45em 0.65em;
  border: 1px solid #f68c8c;
  background: transparent;
  color: #f68c8c;
  &:hover {
    color: white;
    background: #f68c8c;
    border: 1px solid #f68c8c;
  }
}
.preview-template-view {
  margin-bottom: 3em;
  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .preview-form-header {
    margin-top: 1em;
    h1 {
      font-size: 2.1em;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }
    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }
  .header-action-block {
    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin-top: 0.5em;
      &.el-button--danger {
        color: #ffffff;
      }
    }
  }
  .footer-action-block {
    .el-button {
      display: block;
      margin: 2em auto 0;
      padding: 0.75em 2em;
      width: 180px;
      font-size: 1.125em;
    }
    margin-top: 1em;
  }
  .form-builder {
    position: relative;
    width: 100%;
    .form-fields-holder {
      .each-element img {
        max-width: 100%;
      }
    }
  }
}
</style>
