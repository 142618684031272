<template>
  <div class="new-content-box" v-if="chatbot">
    <div class="new-box-heading">
      <img src="@/assets/Kodefast.svg" />
      <span>Try Using AI</span>
      <el-tooltip content="Preview" placement="top">
        <i
          class="el-icon-view"
          style="margin-left: auto; font-size: 15px; cursor: pointer"
          @click="OpenPreview"
        ></i>
      </el-tooltip>
      <span class="divider"></span>
      <el-link :underline="false" class="Summarize" @click="generateSummary"
        ><span>Summarize</span></el-link
      >
      <span class="divider"></span>
      <i
        class="el-icon-close"
        style="font-size: 15px; cursor: pointer"
        @click="CloseChatBot"
      ></i>
    </div>
    <div>
      <div
        v-if="loading"
        :class="
          isFromDocument
            ? 'nested-box-docs loading-container'
            : 'nested-box loading-container'
        "
      >
        <img :src="emailTemplate" alt="Loading..." />
        <p>Just a moment, we are creating an email template for you.</p>
      </div>
      <div v-else :class="isFromDocument ? 'nested-box-docs' : 'nested-box'">
        <div v-html="currentResponse" v-if="disableBox"></div>
      </div>
    </div>
    <div class="select-container">
      <div>
        <div class="mt-1">
          Entity Fields
          <span>
            <el-popover
              class="Description"
              placement="top-start"
              trigger="hover"
              width="443"
              :content="`Please choose the fields you'd like to include in the ${
                isFromDocument ? 'Template.' : 'Email template.'
              }`"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </div>

        <el-select
          v-if="type == 'email_template' || type == 'document_template'"
          v-model="selectedFields"
          multiple
          placeholder="Select"
          collapse-tags
          class="select-box mb-10"
          filterable
          :filter-method="filterEntityFields"
          @change="handleSelectChange"
        >
          <el-option
            v-for="item in filteredEntityFields"
            :key="item.key"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <div class="mt-1">
          <div v-if="!isFromDocument">Global Fields</div>
        </div>
        <el-select
          v-if="type == 'email_template'"
          v-model="selectedGlobalFields"
          multiple
          placeholder="Select"
          collapse-tags
          class="select-box mb-10"
          filterable
          :filter-method="filterGlobalFields"
        >
          <el-option
            v-for="item in filteredGlobalFields"
            :key="item.key"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="input-with-icon">
      <el-input
        autosize
        placeholder="Enter Desired Prompt Here"
        type="textarea"
        v-model="input1"
        @input="handleInput"
        class="inherit-font"
      ></el-input>
      <img
        :src="iconSrc"
        class="suffix-icon"
        alt="Enter Icon"
        @click="handleIconClick"
      />
    </div>
    <div v-if="responseGenerated">
      <div class="icon-container">
        <div style="margin-right: 5px; margin-bottom: 5px">
          <el-tooltip content="Previous" placement="top">
            <i
              class="el-icon-arrow-left"
              @click="handleArrowClick('left')"
              style="cursor: pointer"
            ></i>
          </el-tooltip>

          <span>{{ currentResponseIndex + 1 }}</span>
          <el-tooltip content="Next" placement="top">
            <i
              class="el-icon-arrow-right"
              style="cursor: pointer"
              @click="handleArrowClick('right')"
            ></i>
          </el-tooltip>
        </div>
        <div>
          <el-tooltip content="Copy" placement="top">
            <img src="@/assets/Copy.svg" @click="copyContent(false)" />
          </el-tooltip>
          <el-tooltip
            content="Append"
            placement="top"
            style="font-size: 18px; margin-right: 6px"
          >
            <i class="el-icon-plus" @click="copyContent(true)"></i>
          </el-tooltip>
          <el-tooltip content="Regenerate" placement="top">
            <img
              src="@/assets/Regenerate.svg"
              @click="setPromptDetail('Regenerate', 'regenerate')"
            />
          </el-tooltip>
          <div class="image-container">
            <img src="@/assets/Sliders.svg" alt="Sliders" class="hover-image" />
            <div class="hover-items">
              <div
                class="item"
                @click="setPromptDetail('ChangeFormate', 'short')"
              >
                Short
              </div>
              <div
                class="item"
                @click="setPromptDetail('ChangeFormate', 'Medium')"
              >
                Medium
              </div>
              <div
                class="item"
                @click="setPromptDetail('ChangeFormate', 'long')"
              >
                Eloborate
              </div>
            </div>
          </div>
          <div class="image-container">
            <img
              src="@/assets/emoji-smile.svg"
              alt="Sliders"
              class="hover-image"
            />
            <div class="hover-items">
              <div class="item" @click="setPromptDetail('Formate', 'Formal')">
                Formal
              </div>
              <div class="item" @click="setPromptDetail('Formate', 'Informal')">
                Informal
              </div>
              <div class="item" @click="setPromptDetail('Formate', 'Friendly')">
                Friendly
              </div>
              <div
                class="item"
                @click="setPromptDetail('Formate', 'Persuasive')"
              >
                Persuasive
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dialog-component
      :title="'Preview'"
      :visible="PreviewContent"
      @before-close="PreviewContent = false"
      :containerWidth="'40%'"
      :containerMaxHeight="'70%'"
      :containerMinHeight="'30%'"
      :isShowFooter="false"
    >
      <div class="PreviewOfContent" v-html="currentResponse"></div>
    </dialog-component>
    <dialog-component
      :title="'Email Summary'"
      :visible="EmailSummary"
      @before-close="EmailSummary = false"
      :containerWidth="'40%'"
      :containerMaxHeight="'60%'"
      :containerMinHeight="'20%'"
      :isShowFooter="false"
    >
      <div class="PreviewOfContent">
        <p>
          Here is the summary of your Email Template <b>{{ entity.name }}</b>
        </p>
        <p>
          This <b>{{ entity.name }} </b>includes the following fields:
        </p>
        <ul>
          <li v-for="field in selectedFields" :key="field">{{ field }}</li>
          <li v-for="Globalfield in selectedGlobalFields" :key="Globalfield">
            {{ Globalfield }}
          </li>
        </ul>
        <br />
        <p>
          for the purpose of <b>{{ entity.name }} </b>
        </p>
        <p>Thank you.</p>
      </div>
    </dialog-component>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isLargeScreen: false,
      input1: "",
      EmailSummary: false,
      showCurrentScreen: true,
      chatbot: true,
      PreviewContent: false,
      nestedText: "",
      updatePromptFlag: false,
      loading: false,
      aIGeneratedFields: [],
      options: [],
      iconSrc: require("@/assets/Enter-Active.svg"),
      emailTemplate: require("@/assets/Email-Template-generate.gif"),
      selectedFields: [],
      selectedGlobalFields: [],
      Regenerate: "",
      ChangeFormate: "",
      Formate: "",
      summaryText: "",
      filteredEntityFields: [],
      filteredGlobalFields: [],
      disableBox: true,
      responseGenerated: false,
      regeneratedResponses: [],
      currentResponseIndex: 0,
      isChanges: false,
    };
  },
  mounted() {
    if (this.type == "email_template") {
      this.input1 = `Generate Email Template for ${this.entity.name}`;
    } else {
      this.input1 = `Generate Content for ${this.entity}`;
    }
    this.session_id = `${this.getActiveWorkspace.company_id}#${this.getAuthenticatedUser._id}`;
    this.filteredEntityFields = this.entity_fields;
    this.filteredGlobalFields = this.global_fields;
  },
  props: ["entity_fields", "entity", "type", "global_fields", "isFromDocument"],
  methods: {
    handleSelectChange(selectedItems) {
      if (this.isFromDocument) {
        this.$emit("change", selectedItems);
      }
    },
    OpenEmailSummary() {
      this.EmailSummary = true;
    },
    updateEntityFields(newFields) {
      if (this.isFromDocument) {
        this.filteredEntityFields = newFields;
      }
    },
    CloseChatBot() {
      this.$emit("close-chatbot", this.chatbot);
    },
    handleInput() {
      if (this.input1.length > 0 && !this.loading) {
        this.iconSrc = require("@/assets/Enter-Active.svg");
      } else {
        this.iconSrc = require("@/assets/Enter-Inactive.svg");
      }
    },
    filterEntityFields(query) {
      if (query !== "") {
        this.filteredEntityFields = this.entity_fields.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase());
        });
      } else {
        this.filteredEntityFields = this.entity_fields;
      }
    },
    filterGlobalFields(query) {
      if (query !== "") {
        this.filteredGlobalFields = this.global_fields.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase());
        });
      } else {
        this.filteredGlobalFields = this.global_fields;
      }
    },
    copyContent(isAppend = false) {
      this.$emit("copy-content", {
        content: this.currentResponse,
        isAppend: isAppend,
        AIFields: this.aIGeneratedFields,
      });
    },
    getGlobalFileds() {
      this.selectedGlobalFields = [];
      this.global_fields.map((field) => {
        this.selectedGlobalFields.push(field.label);
      });
    },
    getEntityFields() {
      this.selectedFields = [];
      this.entity_fields.map((field) => {
        this.selectedFields.push(field.label);
      });
    },
    handleIconClick() {
      if (!this.loading && this.input1.length > 0) {
        this.generateResponse();
      }
    },
    async generateResponse() {
      if (this.input1.length > 0 || this.updatePromptFlag) {
        try {
          this.loading = true;
          this.iconSrc = require("@/assets/Enter-Inactive.svg");
          this.responseGenerated = false;
          this.disableBox = false;
          let prompt;
          if (this.Regenerate === "regenerate") {
            prompt = `regenerate by adding or changing the content (${this.selectedFields.join(
              ", "
            )} ${this.selectedGlobalFields.join(", ")})`;
          } else if (this.updatePromptFlag) {
            prompt = `${this.ChangeFormate} ${
              this.Formate
            }. Please mention all the following variables (${this.selectedFields.join(
              ", "
            )} ${this.selectedGlobalFields.join(
              ", "
            )}) between [[ and ]] in the HTML body and also generate the email HTML template with padding and clear the box-shadow and background-color from all the inline styles.`;
          } else {
            if (
              this.selectedFields.length === 0 &&
              this.selectedGlobalFields.length === 0 &&
              !this.isFromDocument
            ) {
              prompt = `${
                this.input1
              }. Please mention all the following variables ([${this.selectedFields.join(
                ", "
              )}]) in the HTML body and also generate the email HTML template with padding and clear the box-shadow and background-color from all the inline styles.`;
            } else if (
              this.selectedFields.length > 0 &&
              this.selectedGlobalFields.length === 0
            ) {
              prompt = `${
                this.input1
              }. Please mention all the following variables ([${this.selectedFields.join(
                ", "
              )}]) in the HTML body and also generate the email HTML template with padding and clear the box-shadow and background-color from all the inline styles.`;
            } else if (
              this.selectedFields.length === 0 &&
              this.selectedGlobalFields.length > 0
            ) {
              prompt = `${
                this.input1
              }. Please mention all the following variables [${this.selectedGlobalFields.join(
                ", "
              )}] in the HTML body and also generate the email HTML template with padding and remove the box-shadow and background-color from all the inline styles.`;
            } else {
              prompt = `${
                this.input1
              }. Please mention all the following variables ([${this.selectedFields.join(
                ", "
              )}])([${this.selectedGlobalFields.join(
                ", "
              )}]) in the HTML body and also generate the email HTML template with padding and remove the box-shadow and background-color from all the inline styles.`;
            }
          }
          let response = await axios.post(
            "https://ai.esigns.io/emailtemplate/",
            {
              Enter_your_prompt: prompt,
              session_id: this.session_id,
              flag: !this.isChanges,
              include_fields: this.isFromDocument || false,
            }
          );
          let data =
            response && response.data && response.data.data
              ? response.data.data
              : response.data;
          this.aIGeneratedFields =
            response && response.data && response.data.fields
              ? response.data.fields
              : [];
          let cleanData = data.replace(/html/g, "");
          if (this.selectedGlobalFields.length > 0) {
            this.selectedGlobalFields.map((fd) => {
              cleanData = cleanData.replace(`[[${fd}]]`, `((${fd}))`);
            });
          }
          let array;
          if (cleanData.includes("```")) {
            array = cleanData.split("```");
            if (array?.length) {
              this.nestedText = array[1];
            } else {
              this.nestedText = "";
            }
          } else {
            array = cleanData.split("**Variables:**");
            if (array?.length) {
              this.nestedText = array[0];
            } else {
              this.nestedText = "";
            }
          }

          this.regeneratedResponses.push(this.nestedText);
          this.currentResponseIndex = this.regeneratedResponses.length - 1;
          this.loading = false;
          this.disableBox = true;
          this.responseGenerated = true;
          this.handleInput();
          this.updatePromptFlag = false;
          this.Regenerate = "";
        } catch (error) {
          console.error("Error generating response:", error);
          this.loading = false;
          this.handleInput();
        }
      }
    },
    async setPromptDetail(type, value) {
      if (
        this.selectedFields.length === 0 &&
        this.selectedGlobalFields.length === 0
      ) {
        this.$message.error({
          showClose: true,
          message: "Please select some fields",
          center: true,
        });
        return;
      }
      this[type] = value;
      this.updatePromptFlag = true;
      this.isChanges = true;
      await this.generateResponse();
    },
    handleArrowClick(direction) {
      if (direction === "left") {
        this.currentResponseIndex = Math.max(0, this.currentResponseIndex - 1);
      } else if (direction === "right") {
        this.currentResponseIndex = Math.min(
          this.regeneratedResponses.length - 1,
          this.currentResponseIndex + 1
        );
      }
    },
    OpenPreview() {
      if (!this.nestedText) {
        this.$message.error({
          showClose: true,
          message: "Generate Content to View",
          center: true,
        });
      } else {
        this.PreviewContent = true;
      }
    },
    generateSummary() {
      if (this.selectedFields.length === 0) {
        this.$message.error({
          showClose: true,
          message: "Please select some fields",
          center: true,
        });
      } else {
        this.OpenEmailSummary();
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    currentResponse() {
      return this.regeneratedResponses[this.currentResponseIndex] || "";
    },
  },
  watch: {
    "entity._id": {
      handler() {
        (this.filteredEntityFields = []), (this.selectedFields = []);
        this.selectedGlobalFields = [];
        this.nestedText = "";
        this.ChangeFormate = "";
        this.Formate = "";
        this.input1 = `Generate Email Template for ${this.entity.name}`;
        this.regeneratedResponses = [];
        this.currentResponseIndex = 0;
      },
      immediate: true,
      deep: true,
    },
    entity_fields: {
      handler(newVal) {
        this.updateEntityFields(newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.inherit-font ::v-deep .el-textarea__inner {
  font-family: "Inter";
  font-size: 13px;
}
.Description {
  color: #c0c4cc;
}
.select-container {
  display: flex;
  gap: 28px;
}
.divider {
  border-left: 1px solid #dcdfe6;
  height: 20px;
  margin: 0 8px;
  display: inline-block;
  vertical-align: middle;
}
.PreviewOfContent {
  scrollbar-width: thin;
  height: 40vh;
  overflow-y: auto;
}
.Summarize {
  color: #409eff;
}
.image-container {
  position: relative;
  display: inline-block;
}

.hover-image {
  display: block;
}
.el-select ::v-deep .el-tag {
  max-width: 65% !important;
}
.hover-items {
  display: none;
  position: absolute;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.hover-items::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 45%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.image-container:hover .hover-items {
  display: block;
}

.item {
  font-size: 15px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.item:hover {
  background-color: #f0f0f0;
}

.input-with-icon {
  position: relative;
  display: inline-block;
  width: 100%;
}

.suffix-icon {
  position: absolute;
  top: 7px;
  right: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.input-with-icon .el-input__inner {
  padding-right: 40px;
}
.check .el-input__inner + .el-input__icon {
  font-size: 100px;
}
.select-box {
  width: 100%;
  max-width: 223px;
  margin-top: 5px;
}

.new-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.new-content-box {
  background-color: #ffffff;
  border: 1px solid #dcdfe6;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 600px) {
    margin-left: 200px;
    width: 60%;
  }
}

.new-box-heading {
  font-size: 18px;
  color: #333333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.nested-box-docs {
  color: black;
  overflow-y: auto;
  scrollbar-width: thin;
  background-color: #f8fafc;
  height: 360px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.nested-box {
  color: black;
  overflow-y: auto;
  scrollbar-width: thin;
  height: 40vh;
  background-color: #f8fafc;
  height: 260px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.icon-container {
  display: flex;
  margin-top: 14px;
  margin-left: 2px;
}
.icon-container img {
  margin-right: 10px;
  height: 16px;
}
@media (max-width: 1366px) and (min-width: 768px) {
  .nested-box {
    height: 185px;
  }
}
</style>
